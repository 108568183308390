export const footerData = [
  {
    label: 'Get In Touch',
    href: '/',
    children: [
      { href: '/', label: 'Home' },
      { href: '/about', label: 'About AK' },
      {
        label: 'Services',
        href: '/service',
        children: [
          {
            href: '/service/main/1',
            label: 'Audit & Assurance Services'
          },
          { href: '/service/main/2', label: 'Advisory & Consulting Services' },
          { href: '/', label: 'Business Services' },
          { href: '/', label: 'Business Start Up Services' },
          { href: '/', label: 'Business Restructing Services' },
          { href: '/', label: 'Mergers & Acquisitions Services' }
        ]
      },
      { href: '/', label: 'Insights' },
      { href: '/', label: 'Contact AK' }
    ]
  },
  {
    label: 'Services',
    href: '/',
    children: [
      {
        label: 'Audit & Assurance Services',
        href: '/service/main/1',
        backgroundColor: '#212974',
        children: [
          { text: 'External Audit', link: '/external-audit' },
          { text: 'Internal Audit', link: '/internal-audit' },
          { text: 'Financial Review', link: '/financial-review' },
          { text: 'Tax Audit', link: '/tax-audit' },
          { text: 'Compliance Assurance', link: '/compliance-assurance' }
        ]
      },

      {
        label: 'Advisory & Consulting Services',
        href: '/service/main/2',
        backgroundColor: '#d14635',

        children: [
          { text: ' CFO Services', link: '/external-audit' },
          { text: ' Accounting & Bookkeeping', link: '/internal-audit' },
          { text: ' Accounting Setup', link: '/financial-review' },
          { text: 'Financial Analysis', link: '/tax-audit' },
          { text: 'Budgeting & Forecasting', link: '/compliance-assurance' }
        ]
      },
      {
        label: 'Business Services',
        href: '/service/main/3',
        backgroundColor: '#0e5948',

        children: [
          { text: ' Strategy Consulting', link: '/external-audit' },
          { text: ' Tax Consulting', link: '/internal-audit' },
          { text: ' Valuation Services', link: '/financial-review' },
          {
            text: ' Mergers & Acquisitions',
            link: '/tax-audit'
          },
          { text: 'Islamic Finance Advisory	', link: '/compliance-assurance' }
        ]
      },
      {
        label: 'Business Start Up Services',
        href: '/service/main/4',
        backgroundColor: '#452565',

        children: [
          { text: ' Startup Consulting', link: '/external-audit' },
          { text: 'Feasibility Study', link: '/internal-audit' },
          { text: 'Business Plan', link: '/financial-review' },
          { text: 'Budgeting', link: '/tax-audit' },
          { text: 'Pricing Decisions', link: '/compliance-assurance' }
        ]
      },
      {
        label: 'Business Restructing Services',
        href: '/service/main/5',
        backgroundColor: '#6abe83',

        children: [
          { text: ' Restructuring Strategy', link: '/external-audit' },
          { text: 'Financial Restructuring', link: '/internal-audit' },
          { text: 'Operational Restructuring', link: '/financial-review' },
          { text: 'Business Recovery', link: '/tax-audit' },
          { text: ' Tax Planning', link: '/compliance-assurance' }
        ]
      },
      {
        label: 'Mergers & Acquisitions Services',
        href: '/service/main/6',
        backgroundColor: '#4d122e',

        children: [
          { text: 'External Audit', link: '/external-audit' },
          { text: 'Internal Audit', link: '/internal-audit' },
          { text: 'Financial Review', link: '/financial-review' },
          { text: 'Tax Audit', link: '/tax-audit' },
          { text: 'Compliance Assurance', link: '/compliance-assurance' }
        ]
      }
    ]
  },

  {
    label: 'Blog',
    href: '/insights',
    children: [
      { href: '/', label: 'Tax' },
      { href: '/', label: 'Business' },
      { href: '/', label: 'General' },
      { href: '/', label: 'Case Studies' }
    ]
  },
  {
    label: 'Careers',
    href: '/',
    children: [
      { href: '/contact', label: 'Contacts Us' },
      { href: '/rfp', label: 'Request for proposal' }
    ]
  }
];
