import React, { useState } from 'react';
import { Row, Col, Image, Card, Typography, Menu, Button } from 'antd';
import { subServices } from '../../data/data';

import styles from './hero.module.css';

const { Link } = Typography;
const HeroSection = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={styles.serviceContainer}
        > 
          <Row gutter={[0, 16]}>
            {subServices.map((service, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={8}
                key={index}
                className={styles.menuContainer}
              >
                <Card
                  style={{ backgroundColor: service.backgroundColor }}
                  className={styles.flipCard}
                >
                  <div className={styles.flipCardInner}>
                    <div className={styles.flipCardFront}>
                      <Link href={service.href}>
                        <Image
                          preview={false}
                          width={120}
                          height={120}
                          src="/logo.png"
                        />
                        <h3>{service.label}</h3>
                      </Link>
                    </div>
                    <div className={styles.flipCardBack}>
                      <h3 style={{ backgroundColor: service.backgroundColor }}>
                        {service.label}
                      </h3>
                      <Menu
                        className={styles.flipCardBackMenu}
                        mode="vertical"
                        theme="none"
                      >
                        {' '}
                        <Button
                          className={styles.hamburgerButton}
                          onClick={handleMenuToggle}
                        >
                          ☰
                        </Button>
                        {service.children.map((child, childIndex) => (
                          <Menu.Item key={childIndex}>
                            <Link href={child.link}>{child.text}</Link>
                          </Menu.Item>
                        ))}
                      </Menu>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className={styles.imageContainer}>
          <div className={styles.imageWrapper}>
            <Image preview={false} width={950} height={615} src="/sample.jpg" />
            <div className={styles.overlay}>
              <div className={styles.hoverButton}>
                <Button className={styles.button1}>Request for proposal</Button>
                <Button className={styles.button2}>Careers</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeroSection;
