import React from 'react';
import { subServices } from '../data/data';
import styles from './Service.module.css';
import Form from '../components/general/Form';
import { Card, Carousel, Col } from 'antd';
import { Helmet } from 'react-helmet-async';
import HeadingComponent from '../components/reusable/HeadComponent';
const Service = () => {
  const inputFields = [
    {
      id: 'fname',
      name: 'name',
      type: 'text',
      pattern: null,
      placeholder: 'Your name..',
    },
    {
      id: 'Position',
      name: 'Cname',
      type: 'text',
      pattern: null,
      placeholder: 'Position',
    },
    {
      id: 'Pnumber',
      name: 'Pnumber',
      type: 'tel',
      pattern: '[0-9]*',

      placeholder: 'Mobile Number.',
    },
    {
      id: 'email',
      name: 'email',
      type: 'email',
      pattern: null,
      placeholder: ' Email sample@gmail.com.',
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          Elevate Your Finances with the Best Auditing Services in Doha, Qatar |
          Services
        </title>
        <meta
          name="description"
          content="Delivering the Best Auditing Services in Doha, Qatar. Trust our expertise for gaining the most excellent services from AK Auditors."
        />
        <meta name="keywords" content="Best Auditing Services in Doha, Qatar" />
      </Helmet>
      <div>
        <HeadingComponent
          className={styles.ServiceHead}
          headingLevel="h2"
          text={`Our Services`}
        />
        <div className={styles.ServiceContainer}>
          <div className={styles.GridContainer}>
            {subServices.map((service, index) => (
              <Col
                style={{ backgroundColor: service.backgroundColor }}
                key={`service-${index}`}
                className={styles.serviceGrid}
              >
                <h2>
                  <a href={service.href} style={{ color: 'Beige ' }}>
                    {service.label}
                  </a>
                </h2>
                <ul>
                  {service.children.map((child, childIndex) => (
                    <li key={`child-${childIndex}`}>
                      <a href={child.link}>{child.text}</a>
                    </li>
                  ))}
                </ul>
              </Col>
            ))}
          </div>

          <div className={styles.formContainer}>
            <Form
              heading={`Request for Proposal`}
              inputFields={inputFields}
              style={{ color: 'red' }}
            />
            <div className={styles.carousel}>
              <h3>TESTIMONALS</h3>
              <Carousel vertical autoplay className={styles.CarouselContainer}>
                <Card title="Company Name 1" bordered={false}>
                  Lorem ipsum dolor sit amet consecte
                </Card>

                <Card title="Company Name 2" bordered={false}>
                  Card content
                </Card>

                <Card title="Company Name 3" bordered={false}>
                  Card content
                </Card>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
