import React from 'react';
import { Row, Col, Image, Typography } from 'antd';

import styles from './Service.module.css';
import { serviceData } from '../../data/service';

const { Link } = Typography;
const serviceSection = () => {
  // const cardItems = subServices.map((item, index) => ({
  //   label: item.label,
  //   key: `card-item-${index}`,
  //   href: item.href
  // })); 

  return (
    <div className={styles.serviceSession}>
      <div className={styles.serviceContainer}>
        <h1>OUR SERVICES</h1>
        <Row  >
          {serviceData.map((service, index) => (
            <Col  xs={24}
    sm={12}
    md={8}
    lg={8}
              style={{ margin: 0 }}
               key={index} className={styles.serviceCard}>
              <Link href={service.href} className={styles.serviceCardTitle}>
                <Image
                  preview={false}
                  src={service.src}
                  alt={service.title}
                  style={{ maxHeight: 80, width: '100%', height: 'auto' }}
                />
              </Link>
              <p>{service.description}</p>
              <ul className={styles.serviceCardLinks}>
                {service.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a href={`#${link}`}>{link}</a>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default serviceSection;
