import React from 'react';
import { Typography, Row, Col } from 'antd';
import styles from './Base.module.css';
import { footerData } from '../../data/footer';
import {
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  InstagramOutlined 
} from '@ant-design/icons'; 

const { Link } = Typography;
const socialIcons = [
  { icon: <TwitterOutlined />, href: 'https://twitter.com' },
  { icon: <FacebookOutlined />, href: 'https://facebook.com' },
  { icon: <LinkedinOutlined />, href: 'https://linkedin.com' },
  { icon: <InstagramOutlined />, href: 'https://instagram.com' }
];

const Base = () => {
  return (
    <div className={styles.footer}>
      <Row className={styles.footerSection} gutter={[16, 16]}>
        {footerData.map((section, index) => (
          <Col className={styles.footerCol}  xs={12} sm={8} md={4} lg={4} key={index}>
            <h3 href={section.href}>{section.label}</h3>
            <ul className={styles.footerMenu}>
              {section.children.map((child, childIndex) => (
                <li key={childIndex}>
                  <Link href={child.href}>{child.label}</Link>
                </li>
              ))}
            </ul>
          </Col>
        ))}
      </Row>

      <div className={styles.socialIcons}>
        {socialIcons.map((social, socialIndex) => (
          <a
            key={socialIndex}
            href={social.href}
            target="noopener"
          >
            {social.icon}
          </a>
        ))}
      </div>

      <p>© 2023 AK & Partners. All Rights Reserved.</p>
    </div>
  );
};

export default Base;
