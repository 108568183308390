export const headerData = [
  { href: '/  ', label: 'Home' },
  { href: '/about/p1', label: 'About AK' },
  {
    label: 'Services',
    children: [
      {
        href: '/service/main/1 ',
        label: 'Audit & Assurance Services'
      },
      { href: '/service/main/2', label: 'Advisory & Consulting Services' },
      { href: '/service/main/3', label: 'Business Services' },
      { href: '/service/main/4', label: 'Business Start Up Services' },
      { href: '/service/main/5', label: 'Business Restructing Services' },
      { href: '/service/main/6', label: 'Mergers & Acquisitions Services' }
    ],
    href: '/service'
  },
  { href: '/insights', label: 'Insights' },
  { href: '/contact', label: 'Contact AK' }
];
