import React from 'react';
import styles from '../../pages/ServicePages/SubServicePages/SubServices.module.css';
import { Carousel } from 'antd';
import Form from '../../components/general/Form';
import { carouselData } from '../../data/carousel';
import HeadingComponent from './HeadComponent';

const SubServiceItem = ({ data }) => {
  const cardsPerSlide = 3;
  const headingStyle = { color: data.color };
  const bgStyle = { backgroundColor: data.color };
  return (
    <div>
      <HeadingComponent
        style={bgStyle}
        className={styles.subServiceHead}
        text={data.label}
      />
      <div className={styles.subServiceContainer}>
        <HeadingComponent
          className={styles.subServiceDefine}
          description={data.description}
          textStyle={headingStyle}
          text={`What is ${data.label} ?`}
        />

        {data.children && data.children.length > 0 && (
          <div className={styles.subServiceSession}>
            <HeadingComponent
              style={headingStyle}
              text={`Why choose AK & Partners Auditors for ${data.label} ?`}
            />

            <ul>
              {data.children.map((child, index) => (
                <li key={index}>
                  <HeadingComponent
                    className={styles.label}
                    textStyle={headingStyle}
                    headingLevel="div"
                    text={`${child.label} :`}
                    description={child.description}
                    descriptionClass={styles.description}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className={styles.formContainer}>
        <h3 style={headingStyle}>TESTIMONIALS</h3>
        <div className={styles.carouselContainer}>
          <Carousel
            autoplay
            swipeToSlide={true}
            speed={500}
            draggable
            className={styles.carousel}
          >
            {carouselData
              .reduce((slides, item, index) => {
                if (index % cardsPerSlide === 0) {
                  slides.push([]);
                }
                slides[slides.length - 1].push(item);
                return slides;
              }, [])
              .map((slide, slideIndex) => (
                <div key={slideIndex} className={styles.cardContainer}>
                  {slide.map((item, cardIndex) => (
                    <HeadingComponent
                      className={styles.card}
                      textStyle={headingStyle}
                      text={item.title}
                      headingLevel="h2"
                      description={item.description}
                    />
                  ))}
                </div>
              ))}
          </Carousel>
        </div>
      </div>
      <div className={styles.Form} style={headingStyle}>
        <Form />
      </div>
    </div>
  );
};

export default SubServiceItem;
