import React from 'react';
import HeadingComponent from '../components/reusable/HeadComponent';
import styles from './contact.module.css';
import {
  TwitterOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import Link from 'antd/es/typography/Link';
import { Helmet } from 'react-helmet-async';
const socialIcons = [
  { icon: <TwitterOutlined />, href: 'https://twitter.com' },
  { icon: <FacebookOutlined />, href: 'https://facebook.com' },
  { icon: <LinkedinOutlined />, href: 'https://linkedin.com' },
  { icon: <InstagramOutlined />, href: 'https://instagram.com' },
];
const Contact = () => {
  const openGoogleMaps = () => {
    window.open(
      'https://www.google.com/maps/dir/25.2280832,51.4654208/ak+auditors/@25.2501557,51.4420617,13z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3e45cdaf5ff54db7:0x6efb3168ee3917d1!2m2!1d51.4998932!2d25.2732953?entry=ttu',
      '_blank'
    );
  };
  return (
    <div>
      <Helmet>
        <title>
          Contact the Best Audit Company in Doha, Qatar | Contact Us
        </title>
        <meta
          name="description"
          content="Get in touch with us today! Contact the Best Audit Company in Doha, Qatar for expert financial services. We're here to assist you."
        />
        <meta name="keywords" content="Best Audit Company in Doha, Qatar" />
      </Helmet>
      <div className={styles.Header}>
        <HeadingComponent
          text="Contact Us"
          className={styles.InsightHeader}
          headingLevel="h1"
        />
      </div>
      <div className={styles.ContactRow}>
        <HeadingComponent
          text="      Thank you for your interest in AK & Partner services. Please take a
          few moments to complete this form. Documents can be uploaded if needed
          to clarify your request. This mailbox only accepts qualified proposal
          requests for AK & Partner services. All other inquires should be
          directed to our Contact Us page. Resumes and job inquiries should be
          sent through our Careers site."
          className={styles.descriptionHeader}
          headingLevel="h3"
        />
        <div>
          <HeadingComponent
            text=" Email"
            textClass={styles.subHead}
            className={styles.EmailHeader}
            headingLevel="h3"
            description="Use the email form to submit enquiries. We will respond as soon as possible."
            descriptionClass={styles.para}
          />
          <HeadingComponent
            text=" Location"
            className={styles.LocationHeader}
            headingLevel="h3"
            textClass={styles.subHead}
            description={
              <span
                onClick={openGoogleMaps}
                style={{
                  cursor: 'pointer',
                  color: 'grey',
                  textDecoration: 'underline',
                }}
              >
                AK Auditors on Google Maps
              </span>
            }
            descriptionClass={styles.para}
          />
          <HeadingComponent
            text="Request for Proposal"
            textClass={styles.subHead}
            className={styles.ProposalHeader}
            headingLevel="h3"
            description={
              <span>
                Use the{' '}
                <Link
                  href="/rfp"
                  style={{
                    cursor: 'pointer',
                    color: 'grey',
                    fontSize: '1.2em',
                    textDecoration: 'underline',
                  }}
                >
                  {' '}
                  RFP form
                </Link>
                &nbsp; to provide the details of issues that you would like AK &
                Partners assist with
              </span>
            }
            descriptionClass={styles.para}
          />
        </div>
        <div className={styles.mapContainer}>
          <iframe
            title="Custom Location Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14431.677760529466!2d51.4998932!3d25.2732953!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45cdaf5ff54db7%3A0x6efb3168ee3917d1!2sAK%20Auditors!5e0!3m2!1sen!2sqa!4v1703325943713!5m2!1sen!2sqa"
            width="600"
            height="450"
            style={{ border: '0' }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div className={styles.media}>
        <h2>Social Media</h2>
        <div className={styles.socialIcons}>
          {socialIcons.map((social, socialIndex) => (
            <a
              key={socialIndex}
              href={social.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.icon}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
