import {
  FaUser,
  FaFileAlt,
  FaEnvelope,
  FaPhone,
  FaGlobe
} from 'react-icons/fa';

export const iconMapping = [
  {
    label: '33106154 / 33831602 / 33315563',
    icon: FaPhone,
    href: '/contact'
  },
  {
    label: 'Our Profile',
    icon: FaUser,
    file: '/AK_Company_profile.pdf' // Add the file property
  },
  {
    label: 'Request a Proposal',
    icon: FaFileAlt,
    href: '/rfp'
  },
  { label: 'Careers', icon: FaEnvelope, href: '/careers' },
  { label: ' Qatar', icon: FaGlobe, href: '/' }
];
