import React, { useState, useEffect } from 'react';
import { Card, Pagination, Select } from 'antd';
import styles from './Insights.module.css';
import HeadingComponent from '../components/reusable/HeadComponent';
import { blogData } from '../data/blog';
import { Helmet } from 'react-helmet-async';
const { Meta } = Card;
const { Option } = Select;

const Insights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default: All
  const [uniqueCategories, setUniqueCategories] = useState([]);

  useEffect(() => {
    // Update unique categories when blogData changes
    const categories = new Set(blogData.map((item) => item.category));
    const sortedCategories = [...categories].sort();
    setUniqueCategories(sortedCategories);
  }, []);

  // Sorting logic (adjust as needed)
  const sortedBlogData = blogData
    .filter(
      (item) => selectedCategory === 'All' || item.category === selectedCategory
    )
    .sort((a, b) => a.category.localeCompare(b.category));

  // Pagination logic
  const cardsPerPage = 6;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = sortedBlogData.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setCurrentPage(1); // Reset to the first page when the category changes
  };

  return (
    <div>
         <Helmet>
        <title>Elevate Your Business with the Best Audit Firms in Doha, Qatar | Insights</title>
        <meta name="description" content="Explore the top audit firms in Doha, Qatar for valuable insights into industry excellence and expertise." />
        <meta name="keywords" content="Best audit firms in Doha, Qatar" />
      </Helmet>
      <div className={styles.Header}>
        <HeadingComponent
          text="Insights"
          className={styles.InsightHeader}
          headingLevel="h1" 
        />
        <Select
          className={styles.selectedCategory}
          style={{ width: 200, marginBottom: 16 }}
          placeholder="Select Category"
          onChange={handleCategoryChange}
          value={selectedCategory}
        >
          <Option value="All">All Categories</Option>
          {uniqueCategories.map((category, index) => (
            <Option key={index} value={category}>
              {category}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.InsightContainer}>
        {currentCards.map((item, index) => (
          <Card
            key={index}
            hoverable
            className={styles.Card}
            // style={{ width: 200 }}
            cover={<img alt="example" src={item.image} />}
          >
            <Meta
              title={item.title}
              description={item.description}
              className={styles.description}
            />
          </Card>
        ))}
      </div>
      <Pagination
        className={styles.Pagination}
        current={currentPage}
        onChange={handlePageChange}
        pageSize={cardsPerPage}
        total={sortedBlogData.length}
      />
    </div>
  );
};

export default Insights;
