// AboutUs.js
import React from 'react';
import styles from './About.module.css';
import { Menu } from 'antd';
import Form from '../../components/general/Form';
import { aboutData } from '../../data/about';
import { Helmet } from 'react-helmet-async';
import HeadingComponent from '../../components/reusable/HeadComponent';

const ContentSection = ({ title, paragraphs }) => (
  <div>
    <h2>{title}</h2>
    {paragraphs.map((paragraph, index) => (
      <p key={`paragraph-${index}`}>{paragraph}</p>
    ))}
  </div>
);

const AboutUs = () => {
  const inputFields = [
    {
      id: 'fname',
      name: 'Company name',
      type: 'text',
      pattern: null,
      placeholder: 'Company name..',
    },
    {
      id: 'Position',
      name: 'Cname',
      type: 'text',
      pattern: null,
      placeholder: 'Industry',
    },
    {
      id: 'Pnumber',
      name: 'Pnumber',
      type: 'tel',
      pattern: '[0-9]*',

      placeholder: 'Mobile Number.',
    },
    {
      id: 'email',
      name: 'email',
      type: 'email',
      pattern: null,
      placeholder: ' Email sample@gmail.com.',
    },
  ];

  // Find the item with the label "Who we are" in aboutData
  const AboutUsData = aboutData.find((item) => item.label === 'About Us');

  const menuItems = aboutData.map((item, index) => ({
    label: <a href={item.href}>{item.label}</a>,
    key: `menu-item-${index}`,
    path: item.href,
  }));

  return (
    <div>
      <Helmet>
        <title>
          Discover Excellence with the Best Auditors in Doha, Qatar | About us{' '}
        </title>
        <meta
          name="description"
          content="Experience excellence with the best auditors in Doha, Qatar. AK Auditors delivers trusted financial expertise and compliance solutions."
        />
        <meta name="keywords" content="Best auditors in Doha, Qatar" />
      </Helmet>
      <div className={styles.aboutContainer}>
        <HeadingComponent
          className={styles.AboutHead}
          headingLevel="h1"
          text={AboutUsData.title}
        />
        <div className={styles.aboutGrid}>
          <div className={styles.aboutGridItem}>
            {/* Display the Menu */}
            <Menu
              mode="vertical"
              className={styles.headerMenu}
              items={menuItems}
            />
          </div>
          <div className={styles.aboutGridItem}>
            {/* Render the content for "Who We Are" */}
            {AboutUsData && (
              <ContentSection
                title={AboutUsData.title}
                paragraphs={AboutUsData.paragraphs}
              />
            )}
          </div>
          <div className={styles.aboutGridItem}>
            {/* Display the Form */}
            <Form heading={`Request for proposal`} inputFields={inputFields} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
