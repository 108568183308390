import React from 'react';
import styles from './index.module.css';
import { Layout } from 'antd';
import Base from './components/general/Base';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Head from './components/general/Head.jsx';
import Service from './pages/Service.jsx';
import { FloatButton } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';

import AboutUs from './pages/AboutComponents/AboutUs.jsx';
import WhoWeAre from './pages/AboutComponents/WhoWeAre.jsx';
import WhatWeStandFor from './pages/AboutComponents/WhatWeStandFor.jsx';
import {
  AdvisoryService,
  AuditService,
  BusinessService,
  MergerService,
  RestructingService,
  StartUpService
} from './pages/ServicePages/Service.jsx';
import {
  InternalAudit,
  ExternalAudit,
  FinancialReview,
  TaxAudit,
  CorporateReportings,
  ComplianceAssurance
} from './pages/ServicePages/SubServicePages/SubService.jsx';
import Insights from './pages/Insights.jsx';
import Contact from './pages/contact.jsx';
import RFP from './pages/RFP.jsx';
import Careers from './pages/Careers.jsx';
const { Header, Content, Footer } = Layout;
const NotFound = () => (
  <div>
    <h2>404 - Not Found</h2>
    <p>The page you are looking for does not exist.</p>
  </div>
);
const App = () => (
  <div>
    <Layout>
      {/* Header */}
      <Header className={styles.Header}>
        <Head />
      </Header>
      {/* Content */}
      <Content className={styles.content}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/p1" element={<AboutUs />} />
          <Route path="/about/p2" element={<WhoWeAre />} />
          <Route path="/about/p3" element={<WhatWeStandFor />} />
          <Route path="/service/main/1" element={<AuditService />} />
          <Route path="/service/01/11" element={<ExternalAudit />} />
          <Route path="/service/01/12" element={<InternalAudit />} />
          <Route path="/service/01/13" element={<FinancialReview />} />
          <Route path="/service/01/14" element={<TaxAudit />} />
          <Route path="/service/01/15" element={<CorporateReportings />} />
          <Route path="/service/01/16" element={<ComplianceAssurance />} />
          <Route path="/service/main/2" element={<AdvisoryService />} />
          <Route path="/service/main/3" element={<BusinessService />} />
          <Route path="/service/main/4" element={<StartUpService />} />
          <Route path="/service/main/5" element={<RestructingService />} />
          <Route path="/service/main/6" element={<MergerService />} />
          <Route path="/service" element={<Service />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/rfp" element={<RFP />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

      </Content>
      {/* Footer */}
      <Footer className={styles.footer}>
        <Base />
      </Footer>
    </Layout>
    <FloatButton
      className={styles.floatButton}
      icon={<WhatsAppOutlined className={styles.Icon} />}
    />
  </div>
);

export default App;
