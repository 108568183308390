import React from 'react';

import SubServiceItem from '../../../components/reusable/SubServiceItem';
import { subServiceData } from '../../../data/subService';

export const ExternalAudit = () => {
  const externalAuditData = subServiceData[0];

  return <SubServiceItem data={externalAuditData} />;
};

export const InternalAudit = () => {
  const externalAuditData = subServiceData[1];

  return <SubServiceItem data={externalAuditData} />;
};
export const FinancialReview = () => {
  const externalAuditData = subServiceData[2];

  return <SubServiceItem data={externalAuditData} />;
};
export const TaxAudit = () => {
  const externalAuditData = subServiceData[3];

  return <SubServiceItem data={externalAuditData} />;
};
export const CorporateReportings = () => {
  const externalAuditData = subServiceData[4];

  return <SubServiceItem data={externalAuditData} />;
};
export const ComplianceAssurance = () => {
  const externalAuditData = subServiceData[5];

  return <SubServiceItem data={externalAuditData} />;
};
