import React from 'react';

import { subServices } from '../../data/data';
import ServiceItem from '../../components/reusable/ServiceItem';

export const AuditService = ({ serviceData }) => {
  const auditService =subServices[0]
    // serviceData ||
    // subServices.find((item) => item.label === 'Audit & Assurance Services');
  return <ServiceItem service={auditService} />;
};

export const AdvisoryService = () => {
  const auditService = subServices[1]
  return <ServiceItem service={auditService} />;
};

export const BusinessService = () => {
  const auditService = subServices[2]
  //   .find(
  //   (item) => item.label === 'Business Services'
  // );

  return <ServiceItem service={auditService} />;
};

export const StartUpService = () => {
  const auditService =subServices[3]
  return <ServiceItem service={auditService} />;
};

export const RestructingService = () => {
  const auditService = subServices[4]
  return <ServiceItem service={auditService} />;
};

export const MergerService = () => {
  const auditService = subServices[5]
  return <ServiceItem service={auditService} />;
};
