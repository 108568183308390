import React from 'react';
import styles from './Form.module.css';

const Form = ({ heading, inputFields }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    // Get form data
    const formData = new FormData(e.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    // Log form data to the console
    console.log('Form submitted successfully with data:', formObject);
  };

  const renderInputFields = () => {
    return inputFields.map((field) => (
      <div key={field.id} className={styles.col75}>
        <input
          type={field.type}
          id={field.id}
          name={field.name}
          required
          pattern={field.pattern}
          placeholder={field.placeholder}
          autoComplete="off"
        />
      </div>
    ));
  };

  return (
    <div className={styles.form}>
      {heading && <h2>{heading}</h2>}
      <form className={styles.formContainer} onSubmit={handleSubmit}>
        {renderInputFields()}{' '}
        <div className={styles.col75}>
          <textarea
            id="subject"
            name="subject"
            placeholder="Additional Information.."
          ></textarea>
          <div className={styles.col75}>
            <label htmlFor="attachments">Attachments:</label>
            <input
              type="file"
              id="attachments"
              name="attachments"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              multiple
            />
          </div>

          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  );
};

export default Form;
