import React from 'react';

const HeadingComponent = ({
  text,
  style,
  className,
  description,
  textStyle,
  textClass,
  descriptionClass,
  headingLevel = 'h1' // Default to h1 if not provided
}) => {
  const HeadingTag = headingLevel;

  return (
    <div style={style} className={className}>
      <HeadingTag style={textStyle} className={textClass}>
        {text}
      </HeadingTag>

      <p className={descriptionClass}>{description}</p>
    </div>
  );
};

export default HeadingComponent;
