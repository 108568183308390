// Flip Card

import { AiOutlineAudit, AiOutlineGlobal } from 'react-icons/ai';
import { CgInternal, CgDanger } from 'react-icons/cg';
import {
  MdOutlineCurrencyExchange,
  MdOutlinePriceCheck,
  MdOutlineSettingsSuggest
} from 'react-icons/md';
import { HiReceiptTax } from 'react-icons/hi';
import {
  FaHandsHelping,
  FaSearchDollar,
  FaMoon,
  FaChartBar,
  FaSearchPlus,
  FaHandshake,
  FaCalculator
} from 'react-icons/fa';
import { GrVirtualMachine, GrPlan } from 'react-icons/gr';
import { GiCoinflip, GiCoins, GiWallet, GiThorHammer } from 'react-icons/gi';
import { GoLaw } from 'react-icons/go';
import { FaSackDollar, FaMoneyBillTransfer, FaChrome } from 'react-icons/fa6';
import { SiCountingworkspro } from 'react-icons/si';
import { HiLink } from 'react-icons/hi2';
import { LuBookMarked } from 'react-icons/lu';
import { BsRocketTakeoffFill } from 'react-icons/bs';
import { LiaCoinsSolid } from 'react-icons/lia';
import { TbReceiptTax } from 'react-icons/tb';

export const subServices = [
  {
    label: 'Audit & Assurance ',
    href: '/service/main/1',
    backgroundColor: '#212974',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque fugiat
          numquam molestias quaerat magni unde obcaecati natus distinctio
          officiis eius. In, eaque! Sequi alias commodi totam assumenda.
          Maiores, deserunt dignissimos!`,
    children: [
      {
        text: 'External Audit',
        link: '/service/01/11',
        description:
          'Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
        icon: AiOutlineAudit
      },
      {
        text: 'Internal Audit',
        link: '/service/01/12',
        description:
          'Internal Auditing is a critical function within any business, to improve effectiveness of risk management, control, operational policies and governance processes. It is an independent, objective assurance and consulting activity designed to add value and improve the operations of an organization.',
        icon: CgInternal
      },
      {
        text: 'Financial Review',
        link: '/service/01/13',
        description:
          'A systematic examination of the financial statements, records, and processes is a cornerstone of efficient financial management. A proper financial review covering Financial Statements Analysis, Internal Control Assessment, Compliance Verification, Risk Identification, Performance Evaluation will be helpful to provide customized, specific recommendations to the business owners.  ',

        icon: MdOutlineCurrencyExchange
      },
      {
        text: 'Tax Audit',
        link: '/service/01/14',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',

        icon: HiReceiptTax
      },
      {
        text: 'Corporate Reportings',
        link: '/service/01/15',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: AiOutlineGlobal
      },
      {
        text: 'Compliance Assurance',
        link: '/service/01/16',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaHandsHelping
      }
    ]
  },

  {
    label: 'Advisory & Consulting ',
    href: '/service/main/2',
    backgroundColor: '#d14635',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque fugiat
          numquam molestias quaerat magni unde obcaecati natus distinctio
          officiis eius. In, eaque! Sequi alias commodi totam assumenda.
          Maiores, deserunt dignissimos!`,

    children: [
      {
        text: ' Virtual CFO ',
        link: '/external-audit',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: GrVirtualMachine
      },
      {
        text: ' Accounting & Bookkeeping',
        link: '/internal-audit',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: LuBookMarked
      },
      {
        text: ' Accounting Setup',
        link: '/financial-review',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: FaCalculator
      },
      {
        text: 'Financial Analysis',
        link: '/tax-audit',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: FaSearchDollar
      },
      {
        text: 'Budgeting & Forecasting',
        link: '/compliance-assurance',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: GiWallet
      },
      {
        text: 'Compliance ',
        link: '/compliance-assurance',
        description:
          'We provide comprehensive tax audits that go beyond compliance to offer strategic insights and financial clarity. Our team of seasoned auditors are experts in assisting businesses to navigate the complex tax regulations, bringing clarity and confidence  to your tax compliance. ',
        icon: AiOutlineGlobal
      }
    ]
  },
  {
    label: 'Business ',
    href: '/service/main/3',
    backgroundColor: '#0e5948',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque fugiat
          numquam molestias quaerat magni unde obcaecati natus distinctio
          officiis eius. In, eaque! Sequi alias commodi totam assumenda.
          Maiores, deserunt dignissimos!`,

    children: [
      {
        text: ' Strategy Consulting',
        link: '/external-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaChartBar
      },
      {
        text: ' Tax Consulting',
        link: '/internal-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: LiaCoinsSolid
      },
      {
        text: ' Valuation Services',
        link: '/financial-review',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaSearchPlus
      },
      {
        text: ' AML-CFT Consulting',
        link: '/tax-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaHandsHelping
      },
      {
        text: 'Risk Advisory	',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: CgDanger
      },
      {
        text: 'Islamic Finance Advisory	',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaMoon
      }
    ]
  },
  {
    label: 'Business Start Up ',
    href: '/service/main/4',
    backgroundColor: '#452565',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque fugiat
          numquam molestias quaerat magni unde obcaecati natus distinctio
          officiis eius. In, eaque! Sequi alias commodi totam assumenda.
          Maiores, deserunt dignissimos!`,
    children: [
      {
        text: ' Startup Consulting',
        link: '/external-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: BsRocketTakeoffFill
      },
      {
        text: 'Feasibility Study',
        link: '/internal-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: GoLaw
      },
      {
        text: 'Business Plan',
        link: '/financial-review',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: GrPlan
      },
      {
        text: 'Budgeting',
        link: '/tax-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaSackDollar
      },
      {
        text: 'Pricing Decisions',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: MdOutlinePriceCheck
      },
      {
        text: ' Policy Manual',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: GiThorHammer
      }
    ]
  },
  {
    label: 'Business Restructing ',
    href: '/service/main/5',
    backgroundColor: '#48A872',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque fugiat
          numquam molestias quaerat magni unde obcaecati natus distinctio
          officiis eius. In, eaque! Sequi alias commodi totam assumenda.
          Maiores, deserunt dignissimos!`,
    children: [
      {
        text: ' Restructuring Strategy',
        link: '/external-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: MdOutlineSettingsSuggest
      },
      {
        text: 'Financial Restructuring',
        link: '/internal-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaMoneyBillTransfer
      },
      {
        text: 'Operational Restructuring',
        link: '/financial-review',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: SiCountingworkspro
      },
      {
        text: 'Business Recovery',
        link: '/tax-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: GiCoinflip
      },
      {
        text: ' Tax Planning',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: TbReceiptTax
      },
      {
        text: ' Working Capital Optimisation',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: GiCoins
      }
    ]
  },
  {
    label: 'Mergers & Acquisitions ',
    href: '/service/main/6',
    backgroundColor: '#4d122e',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque fugiat
          numquam molestias quaerat magni unde obcaecati natus distinctio
          officiis eius. In, eaque! Sequi alias commodi totam assumenda.
          Maiores, deserunt dignissimos!`,
    children: [
      {
        text: ' Merger',
        link: '/external-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: HiLink
      },
      {
        text: ' Joint Venture',
        link: '/internal-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaHandsHelping
      },
      {
        text: 'Valuation',
        link: '/financial-review',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: GoLaw
      },
      {
        text: 'Acquisition',
        link: '/tax-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaHandshake
      },
      {
        text: ' Synergy Analysis',
        link: '/tax-audit',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: FaChrome
      },
      {
        text: 'Post Merger Integration',
        link: '/compliance-assurance',
        description:
          'Maintaining financial integrity and adherence to regulatory standards is a non-negotiable priority for running a business professionally. At AK & Partners Auditors, our Compliance Assurance services are designed to provide you with a robust framework to navigate the intricate landscape of financial compliance, mitigating risks and ensuring that your organization operates within the boundaries of the law. ',
        icon: MdOutlineSettingsSuggest
      }
    ]
  }
];

// export const imageMapping = {
//   Image1: { src: '/sample.jpg', href: '/' },
//   Image2: { src: '/sample2.jpeg', href: '/' }
// };
export const imageMapping = ['/sample.jpg', '/sample2.jpeg'];

export const blogData = {
  card1: {
    href: 'https://maps.app.goo.gl/TrVQXnqqQVh5QrcK9  ',
    src: 'https://picsum.photos/id/1/300/300',
    description: 'abc1'
  },
  card2: {
    href: '/dgds',
    src: 'https://picsum.photos/id/12/300/300',
    description: 'abc2'
  },
  card3: {
    href: '/dgds',
    src: 'https://picsum.photos/id/32/300/300',
    description: 'abc2'
  },
  card4: {
    href: '/dgds',
    src: 'https://picsum.photos/id/24/300/300',
    description: 'abc4'
  },
  card5: {
    href: '/dgds',
    src: 'https://picsum.photos/id/20/300/300',
    description: 'abc5'
  }
};

// ABOUT AK _HOME---------------------------------------------------------------------------------
