import React from 'react';
import styles from '../../pages/ServicePages/Service.module.css';
import { Image, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';
const { Link } = Typography;
const ServiceItem = ({ service }) => {
  const backgroundColor = service?.backgroundColor;
  const color = service?.backgroundColor;
 if (!service) {
    return null; // Return null or some fallback UI if service is undefined
  }
  return (
    <div>
      <Helmet>
        <title>Elevate Your Finances with the Best Auditing Services in Doha, Qatar | Services</title>
        <meta name="description" content="Delivering the Best Auditing Services in Doha, Qatar. Trust our expertise for gaining the most excellent services from AK Auditors." />
        <meta name="keywords" content="Best Auditing Services in Doha, Qatar" />
      </Helmet>
      <div>
        <div className={styles.ServiceHero} style={{ backgroundColor }}>
          <div className={styles.ServiceLogo}>
            <Link href="/">
              <Image
                preview={false}
                width={150}
                height={150}
                src="/logo.png"
                alt="Logo"
              />
              <h3>{service.label}</h3>
            </Link>
          </div>
          <p style={{ textAlign: 'justify' }}>
            {service.description}
            <br />
            <a href="/">Request For {service.label}</a>
          </p>
        </div>
        <div className={styles.ServicesContainer}>
          {service && (
            <div>
              {service.children && (
                <div className={styles.auditServiceGrid}>
                  {service.children.map((child) => (
                    <div className={styles.auditGridItem}>
                      <a href={child.link}>
                        <div
                          className={styles.auditServicesTitle}
                          style={{ color }}
                        >
                          <child.icon size={60} className={styles.auditIcon} />
                          <h6 href={child.link}>{child.text}</h6>
                        </div>
                      </a>
                      <p>{child.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
