import React from 'react';
import About from '../components/general/About-section';
import ServiceSection from '../components/general/Service-section';
import HeroSection from '../components/general/Hero-section';
import styles from './Home.module.css';
import { Helmet } from 'react-helmet-async';
// import FormSection from '../components/general/form-section';
const Home = () => {
  return (
    <div>
       <Helmet>
        <title>Elevate Your Business with the Best Auditing Firm in Doha, Qatar | AK Auditors</title>
        <meta name="description" content="AK Auditors is the Best auditing firm in Doha, Qatar. Trust our expertise for financial accuracy and compliance. Your success, our priority." />
        <meta name="keywords" content="Best auditing firm in doha, Qatar" />
      </Helmet>
      <HeroSection />
      <div className={styles.homeContent}>
        <About />
      </div>
       <ServiceSection />
  {/*    <FormSection /> */}
    </div>
  );
};

export default Home;
