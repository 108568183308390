export const blogData = [
{
    title: 'How to Achieve the most ambitious goals',
    description: 'Discover the hidden gems of Parisian streets.',
    image: 'https://example.com/paris-street.jpg',
    category: 'Category1'
  },
  {
    title: 'Venice: A Photographer\'s Paradise',
    description: 'Capturing the essence of Venice through the lens.',
    image: 'https://example.com/venice-street.jpg',
    category: 'Category1'
  },
  {
    title: 'The Vibrant Colors of Amsterdam',
    description: 'Immerse yourself in the colorful streets of Amsterdam.',
    image: 'https://example.com/amsterdam-street.jpg',
    category: 'Category1'
  },
  {
    title: 'Street Art in Berlin',
    description: 'Exploring the dynamic street art scene in Berlin.',
    image: 'https://example.com/berlin-street-art.jpg',
    category: 'Category2'
  },
  {
    title: 'London After Dark',
    description: `Experience the allure of London's streets after sunset.`,
    image: 'https://example.com/london-night.jpg',
    category: 'Category2'
  },
  {
    title: `Barcelona's Gothic Quarter`,
    description: 'Journey through the historic Gothic Quarter of Barcelona.',
    image: 'https://example.com/barcelona-gothic.jpg',
    category: 'Category3'
  },
  {
    title: 'The Charm of Prague',
    description: 'Discover the enchanting streets of Prague.',
    image: 'https://example.com/prague-street.jpg',
    category: 'Category3'
  },
  {
    title: 'Istanbul: Where East Meets West',
    description: 'Explore the cultural crossroads of Istanbul.',
    image: 'https://example.com/istanbul-street.jpg',
    category: 'Category4'
  },
  {
    title: 'Santorini: A Photographer\'s Dream',
    description: `Capture the stunning vistas of Santorini's streets.`,
    image: 'https://example.com/santorini-street.jpg',
    category: 'Category5'
  },
  {
    title: 'New York City Hustle',
    description: 'Experience the fast-paced energy of NYC streets.',
    image: 'https://example.com/nyc-street.jpg',
    category: 'Category5'
  }
];
