export const subServiceData = [
  {
    label: 'External Auditing',
    color: '#212974',
    description: `Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. 
We strive to provide comprehensive auditing services including financial statement audits, internal control reviews, compliance audits, etc.  At the same time, we ensure that our services are tailor-made to meet the unique and specific needs of each business. These features make us the best choice for external auditing services in Qatar. 
`,
    children: [
      {
        label: 'Experience and Expertise',
        description:
          ' We have a team of seasoned professionals with extensive experience. They have a deep understanding of industry specific challenges and standards.'
      },
      {
        label: 'Comprehensive & Customized Service',
        description: `We offer comprehensive auditing services including financial statement audits, internal control reviews, compliance audits, and more. All our services are tailor-made to meet the unique requirements of your business.
`
      },
      {
        label: 'Timely & Actionable Reports',
        description: ` We provide timely and actionable insights and recommendations to improve your financial processes and internal control systems.. `
      },
      {
        label: 'Regulatory Compliance',
        description: `Our team is well versed in tax law and regulations. We can help you to ensure that your business is meeting all the compliance requirements.`
      },
      {
        label: 'Ethical and Independent Auditing',
        description: ` Our services are conducted with the utmost integrity and independence, ensuring the client receives unbiased and trustworthy audit reports.`
      }
    ]
  },
  {
    label: 'Internal Audit',
    color: '#212974',
    description: `Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. 
We strive to provide comprehensive auditing services including financial statement audits, internal control reviews, compliance audits, etc.  At the same time, we ensure that our services are tailor-made to meet the unique and specific needs of each business. These features make us the best choice for external auditing services in Qatar. 
`,
    children: [
      {
        label: 'Experience and Expertise',
        description:
          ' We have a team of seasoned professionals with extensive experience. They have a deep understanding of industry specific challenges and standards.'
      },
      {
        label: 'Comprehensive & Customized Service',
        description: `We offer comprehensive auditing services including financial statement audits, internal control reviews, compliance audits, and more. All our services are tailor-made to meet the unique requirements of your business.
`
      },
      {
        label: 'Timely & Actionable Reports',
        description: ` We provide timely and actionable insights and recommendations to improve your financial processes and internal control systems.. `
      },
      {
        label: 'Regulatory Compliance',
        description: `Our team is well versed in tax law and regulations. We can help you to ensure that your business is meeting all the compliance requirements.`
      },
      {
        label: 'Ethical and Independent Auditing',
        description: ` Our services are conducted with the utmost integrity and independence, ensuring the client receives unbiased and trustworthy audit reports.`
      }
    ]
  },
  {
    label: 'Financial Review',
    color: '#212974',
    description: `Internal Auditing is a critical function within any business, to improve effectiveness of risk management, control, operational policies and governance processes. It is an independent, objective assurance and consulting activity designed to add value and improve the operations of an organization.
Our Internal Auditors offer a comprehensive range of Internal Auditing Services, covering Risk Assessment, Process Efficiency reviews, Regulatory Compliance, Fraud detection, Internal Controls, Financial Audits, Process Improvement, etc and will provide a detailed, tailor-made recommendation and reports to help you improve your business.  

`,
    children: [
      {
        label: 'Professional Experience & Industry Expertise',
        description:
          ' We have a team of seasoned professionals with extensive experience. They have a deep understanding of industry specific challenges and standards.'
      },
      {
        label: 'Comprehensive & Customized Service',
        description: `We offer comprehensive auditing services including financial statement audits, internal control reviews, compliance audits, and more. All our services are tailor-made to meet the unique requirements of your business.
`
      },
      {
        label: 'Timely & Actionable Reports',
        description: ` We provide timely and actionable insights and recommendations to improve your financial processes and internal control systems.. `
      },
      {
        label: 'Regulatory Compliance',
        description: `Our team is well versed in tax law and regulations. We can help you to ensure that your business is meeting all the compliance requirements.`
      },
      {
        label: 'Ethical and Independent Auditing',
        description: ` Our services are conducted with the utmost integrity and independence, ensuring the client receives unbiased and trustworthy audit reports.`
      }
    ]
  }
];
