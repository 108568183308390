// Header.js
import React, { useState } from 'react';
import { Menu, Button } from 'antd';
import { headerData } from '../../data/headerData';
import styles from './Head.module.css';
import { iconMapping } from '../../data/icons';
const Head = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const menuItems = iconMapping.map((item, index) => {
    const menuItem = {
      label: <a href={item.href}>{item.label}</a>,
      key: `menu-item-${index}`,
      icon: <item.icon />,
      path: item.href,
    };

    // If the item has a file, add a link for the file
    if (item.file) {
      menuItem.label = (
        <a href={item.file} target="_blank" rel="noopener noreferrer">
          {item.label}
        </a>
      );
    }

    return menuItem;
  });
  const secondMenuItems = headerData.map((item) => {
    const secondMenuItem = {
      label: <a href={item.href}>{item.label}</a>,
      key: item.label,
      className: item.children ? styles.parentMenu : '',
    };

    // If the item has children, map over them and add to the menuItem
    if (item.children) {
      secondMenuItem.children = item.children.map((child) => ({
        label: <a href={child.href}>{child.label}</a>,
        key: child.label,
      }));
    }

    return secondMenuItem;
  });

  return (
    <div className={styles.header}>
      <div className={styles.logoContainer}>
        <img src="/AK-logo.png" className={styles.logo} alt="Your Logo" />
      </div>
      <div className={styles.menuContainer}>
        <Button className={styles.toggleButton} onClick={handleMenuToggle}>
          ☰
        </Button>
        <Menu
          mode="horizontal"
          // darkItemHoverColor="#fff"
          // itemSelectedColor="#fff"
          fontsizelg="true"
          className={`${styles.headerMenu} ${
            isMenuOpen ? styles.menuOpen : ''
          }`}
          items={menuItems}
        />
        <Menu
          mode="horizontal"
          items={secondMenuItems}
          // popupClassName={styles.menuPopup}
          className={`${styles.headerMenu} ${styles.secondMenu} ${
            isMenuOpen ? styles.menuOpen : ''
          }`}
        />
      </div>
    </div>
  );
};

export default Head;
