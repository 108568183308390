import React from 'react';
import { Typography, Menu, Image, Carousel } from 'antd';
import { sectorsMapping } from '../../data/about';
import { CheckOutlined } from '@ant-design/icons';
import styles from './about.module.css';
// import HeadingComponent from '../reusable/HeadComponent';
const { Title, Paragraph, Text, Link } = Typography;
const menuItems = sectorsMapping.map((item, index) => ({
  label: item.label,
  key: `menu-item-${index}`,
  icon: <CheckOutlined />,
  href: item.href
}));

const AboutSection = () => (
  <div className={`${styles.aboutContainer} ${styles.slide}`}>
    <div>
      <Typography className={styles.aboutContent}>
        <Title level={1}>WELCOME TO AK & PARTNERS</Title>
        <Paragraph>
          <Text strong>
            AK is a firm of Chartered Accountants offering feasibility study,
            accounts, audit, taxation and business advisory, advise on Islamic
            finance and support services to a wide range of businesses and 
            individuals.
          </Text>
        </Paragraph>
        <Paragraph>
          By combining professional talent, technology, and a thorough
          understanding of our clients' needs we add value to the client and
          offer high quality, cost-effective and responsive services.
        </Paragraph>
        <Paragraph>
          As a team of consistent, friendly, and proactive professionals we do
          more than just respond to your needs but work alongside to help you
          succeed.
        </Paragraph>
        <Paragraph>
          We offer free initial consultation to establish what we can do for
          you, so please {'\t'}
          <Link href="/contact">contact us</Link> {'\t'}
          or {'\t'}
          <Link href="/rfp">fill the RFP</Link>.{'\t'}
        </Paragraph>
      </Typography>
      <Title level={3}>Major Sectors we are focusing</Title>
      <Paragraph>
        <Menu className={styles.aboutMenu} mode="vertical" items={menuItems} />
      </Paragraph>
    </div>
    <div>
      <Carousel
        autoplay
        vertical
        autoplaySpeed={3000}
        className={styles.carousel}
      >
        <div>
          <Image
            preview={false}
            className={styles.carouselImage}
            width={400}
            height={200}
            src="/sample.jpg"
          />
        </div>
        <div>
          <Image
            preview={false}
            className={styles.carouselImage}
            width={400}
            height={200}
            src="/sample2.jpeg"
          />
        </div>
        <div>
          <Image
            preview={false}
            className={styles.carouselImage}
            width={400}
            height={200}
            src="/sample3.jpeg"
          />
        </div>
      </Carousel>
    </div>
  </div>
);
export default AboutSection;
