export const serviceData = [
  {
    title: 'AUDIT & ASSURANCE SERVICES',
    color: '#212974',
    src: '/audit-assurance.png',
    href: '/service/main/1',
    description:
      'Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
    imageUrl: 'https://picsum.photos/id/1/200/300',
    links: [
      'External Audit',
      'Internal Audit',
      'Financial Review',
      'Tax Audit',
      'Corporate Reportings',
      '    Compliance Assurance'
    ]
  },
  {
    title: 'BUSINESS SERVICES',
    color: '#d14635',
    src: '/business-services.png',
    href: '/service/main/3',

    description:
      'Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
    imageUrl: 'https://picsum.photos/id/1/200/300',
    links: [
      'Virtual CFO Services',
      'Accounting Setup',
      'Accounting & Bookkeeping',
      'Financial Analysis',
      ' Budgeting & Forecasting',
      'Compliance Services'
    ]
  },
  {
    title: 'BUSINESS START UP SERVICES',
    color: '#452565',
    src: '/business-start.png',
    href: '/service/main/4',
    description:
      'Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
    imageUrl: 'https://picsum.photos/id/1/200/300',
    links: [
      ' Startup Consulting',
      ' Feasibility Study',
      ' Business Plan',
      ' Budgeting',
      'Pricing Decisions',
      ' Policy Manual'
    ]
  },
  {
    title: 'ADVISORY AND CONSULTING SERVICES',
    color: '#0e5948',
    src: '/advisory-consulting.png',
    href: '/service/main/2',
    description:
      'Psperienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
    imageUrl: 'https://picsum.photos/id/1/200/300',
    links: [
      ' Strategy Consulting',
      ' Tax Consulting',
      ' Valuation Services',
      'AML-CFT Consulting',
      ' Risk Advisory',
      ' Islamic Finance Advisory'
    ]
  },

  {
    title: ' Mergers & Acquisitions',
    color: '#4d122e',
    href: '/service/main/6',
    src: '/mergers.png',
    description:
      'Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
    imageUrl: 'https://picsum.photos/id/1/200/300',
    links: [
      ' Merger',
      ' Joint Venture',
      ' Valuation',
      ' Acquisition',
      ' Synergy Analysis',
      ' Post Merger Integration'
    ]
  },
  {
    title: 'BUSINESS RESTRUCTURING SERVICES',
    color: '#48A872',
    href: '/service/main/5',
    src: '/business-restructure.png',
    description:
      'Periodical External Audits are essential for ensuring the financial integrity and compliance of the businesses. At AK and Partners Auditors, we offer a team of qualified and experienced auditors who are well versed in Accounting and Auditing standards. We are committed to accuracy, transparency, and regulatory compliance. ',
    imageUrl: 'https://picsum.photos/id/1/200/300',
    links: [
      ' Restructuring Strategy',
      ' Financial Restructuring',
      ' Operational Restructuring',
      ' Business Recovery',
      ' Tax Planning',
      ' Working Capital Optimisation'
    ]
  },
];
