// carouselData.js

export const carouselData = [
  {
    title: 'Company Name 1',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  },
  {
    title: 'Company Name 2',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  },
  {
    title: 'Company Name 3',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  },
  {
    title: 'Company Name 4',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  },
  {
    title: 'Company Name 5',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  },
  {
    title: 'Company Name 6',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  },
  {
    title: 'Company Name 7',
    description:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur explicabo eligendi, numquam, doloremque reprehenderit impedit aperiam ipsam eum non adipisci minima fugit minus corrupti. Fuga nihil voluptas itaque porro recusandae!'
  }
  // Add more items as needed
];
