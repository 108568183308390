import React from 'react';
import HeadingComponent from '../components/reusable/HeadComponent';
import styles from './RFP.module.css';
import Form from '../components/general/Form';
import { Helmet } from 'react-helmet-async';

const RFP = () => {
  const inputFields = [
    {
      id: 'fname',
      name: 'name',
      type: 'text',
      pattern: null,
      placeholder: 'Your name..',
    },
    {
      id: 'Position',
      name: 'Cname',
      type: 'text',
      pattern: null,
      placeholder: 'Position',
    },
    {
      id: 'Pnumber',
      name: 'Pnumber',
      type: 'tel',
      pattern: '[0-9]*',

      placeholder: 'Mobile Number.',
    },
    {
      id: 'email',
      name: 'email',
      type: 'email',
      pattern: null,
      placeholder: ' Email sample@gmail.com.',
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Optimize Success Through Best Audit Company in Doha, Qatar | Careers
        </title>
        <meta
          name="description"
          content="Discover rewarding career opportunities at AK Auditors, the Best Audit Company in Doha, Qatar. Join us in shaping the future of auditing excellence."
        />
        <meta name="keywords" content="Best Audit Company in Doha, Qatar" />
      </Helmet>
      <div className={styles.RFPContainer}>
        <HeadingComponent
          className={styles.heading}
          text="Careers"
          headingLevel="h1"
        />
        <div className={styles.careerContainer}>
          <Form heading="Request for Proposal" inputFields={inputFields} />
          <p>
            Thank you for your interest in AK Partner services. Please take a
            few moments to complete this form. Documents can be uploaded if
            needed to clarify your request. This mailbox only accepts qualified
            proposal requests for AK & Partner services. All other inquires
            should be directed to our Contact Us page. Resumes and job inquiries
            should be sent through our Careers site.
          </p>
        </div>
      </div>
    </>
  );
};

export default RFP;
