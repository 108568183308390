export const sectorsMapping = [
  {
    label: 'Not for profit Organizations',
    href: '/'
  },
  {
    label: 'Real Estate & Construction',
    href: '/profile-link'
  },
  {
    label: 'Health Care',
    href: '/pages/proposal-form'
  },
  { label: 'Trade & E-commerce', href: '/contact-link' },
  { label: ' Technology & Communication', href: '/qatar-link' },
  { label: ' Manufacturing', href: '/qatar-link' },
  { label: ' Energy & Resources', href: '/qatar-link' },
  { label: ' Government', href: '/qatar-link' }
];
// data.js
export const aboutData = [
  {
    label: 'About Us',
    href: '/about/p1',
    title: 'About Us',
    paragraphs: [
      `AK and Partners Auditors is one of the leading Auditors and
            Chartered Accountants, licensed in Qatar. The expert team of
            professionals at AK Auditors offer feasibility study, accounting and
            bookkeeping, internal auditing and external auditing, tax planning
            and advisory, financial and business advisory, AML-CFT Consulting &
            Auditing, ICV Certification & consultancy, advice on Islamic finance
            and support services to a wide range of businesses and individuals.`,
      ` We derive our unique strengths from the decades of strong business &
            commercial experience and expertise of our professionals. We are
            well aware of the in-and-outs of the businesses. This helps us to
            provide better financial and business advice and insights to our
            clients on time. This sets AK & Partners Auditors apart from other
            service providers, making us one of the best auditors and
            consultants in Qatar and the GCC the region.`
    ]
  },
  {
    label: 'Who we are',
    href: '/about/p2',
    title: 'Who We Are',
    paragraphs: [
      `   AK & Partners Auditors are registered with the Ministry of Economy and Commerce and are licensed by Qatar Auditors Accreditation Committee to provide professional services including audit, accounting, tax and business consulting services in Qatar. We are also listed as a service provider for Auditing and Tax Services in  the free zones, including Qatar Financial Centre (QFC), Qatar Free Zone Authority (QFZA), etc.`,
      ` CA Abdul Khader, with more than 23 years of experience in the field, leads the team of AK and Partners Auditors. Our team of professionals includes qualified members from international bodies like ICAI, ACCA, CMA, etc. By combining professional talent, technology, and a thorough understanding of our client's needs, we add lasting value to the client. Our services are high quality, cost-effective and responsive services, with a value-based approach.`,
      `  We use our creativity to develop a vision of exactly what the client
              wants to do, and then we customize our services to meet the unique
              requirements of each client. As a team of consistent, friendly, and
              proactive professionals, we do more than just respond to your needs
              but work alongside to ensure you succeed.`
    ]
  },
  {
    label: 'What we stand for',
    href: '/about/p3',
    title: 'What We Stand For',
    paragraphs: [
      ` Integrity & Excellence: We hold integrity as our guiding principle
            at our core, always choosing the path of ethical conduct and doing
            what's right. Excellence is not a goal; it's a standard we maintain
            in every aspect of our work.`,
      `   Innovation & Impact: Our unwavering belief in innovation drives us
            to seek cutting-edge, efficient solutions. We aspire to create an
            enduring impact, benefiting not only our clients but also society as
            a whole.`,
      `  Growth & Collaboration: Together, we grow, and together, we forge
            lasting collaborations. We don't just serve our clients; we act as
            their partners in their journey to success.`
    ]
  },
  {
    label: 'Testimonials',
    href: '/about/p3',
    title: 'Testimonials',
    paragraphs: [
      // Testimonials content
    ]
  },
  {
    label: 'AK Blog',
    href: '/insights',
    title: 'AK Blog',
    paragraphs: [
      // AK Blog content
    ]
  },
  {
    label: 'AK Services',
    href: '/service',
    title: 'AK Services',
    paragraphs: [
      // AK Services content
    ]
  },
  {
    label: 'Our Media',
    href: '/contact',
    title: 'Our Media',
    paragraphs: [
      // Our Media content
    ]
  }
];
